/*
Version 0.105

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2017.

!Changelog

2017
	01-03-2018
	- [ADDED] Added document.ready to onready.
	30-08-2017
	- [BORN] This class is born. Hooray!

!Usage

	specific_videoplayer.onready();
	
!Wishlist

	none
	
*/
  
var specific_faq = {};

(function(){
	 
	// ONREADY ********************************************************************
	// ONREADY ********************************************************************
	// ONREADY ********************************************************************
	// ONREADY ********************************************************************
	
	this.onready = function() {
		
		$(document).on('click','.js-faq-item',function() {
		
			/*
			Slide all up
			*/
			$('.js-faq-item').find('.answer').stop().slideUp();
			$(this).removeClass('active');

			/*
			Toggle
			*/
			if(
				!$(this).hasClass('active')
			) {

				$(this).addClass('active').find('.answer').stop().slideDown();

			}

		});
		
	};

	// METHODS  *****************************************************************
	// METHODS *****************************************************************
	// METHODS ******************************************************************
	// METHODS ******************************************************************
	
	

		
}).apply(specific_faq); 