/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2021.

!Changelog

2021
	20-11-2021
	- [BORN] This class is born. Hooray!

!Wishlist

	none
	
*/
  
var specific_doneren = {};

(function(){
	 
	// ONREADY ********************************************************************
	// ONREADY ********************************************************************
	// ONREADY ********************************************************************
	// ONREADY ********************************************************************
	
	this.onready = function() {
			
		$(document).on('click','.js-sponsor-item-button',function() {

			var _id 	= $(this).data('id');
			var _price 	= 0;

			if(_id == 110 || _id == 111) {
				_price = parseInt($('.js-open-payment-desktop').val());
			}

			specific_doneren.updateCart(
				_id,
				'add',
				_price
			);

		});

		$(document).on('keypress','.js-open-payment-desktop, js-open-payment-mobile', function(event) {
	        var charCode = (event.which) ? event.which : event.keyCode;
	        
	        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 44) {
	            event.preventDefault();
	        }

	    });

	    $(document).on('click','.js-cart-invoice-moreinfo', function(event) {
	    	alert('Wij sturen een zakelijke factuur op via het ingevoerde e-mailadres.');
	    });

		/*
		Add or Deduct
		*/
		$(document).on('click','.js-addorremove',function() {

			var _id 		= $(this).closest('.cart__item').data('id');
			var _amount 	= parseInt($(this).parent().parent().find('.amount').find('strong').html());
			var _price 		= $(this).closest('.cart__item').data('price');

			switch(
				$(this).data('type')
			) {

				case 'add':
					_amount = (_amount + 1);
				break;

				case 'remove':
					_amount = (_amount - 1);
				break;

			}

			/*
			Update: Amount
			*/

			console.log(_amount);

			if(_amount <= 0) {


				$('.cart__item[data-id="' + _id + '"').remove();
			} else {
				$(this).parent().parent().find('.amount').find('strong').html(_amount);
			}

			/*
			AJAX
			*/
			specific_doneren.updateCart(
				_id,
				$(this).data('type'),
				_price
			);

		});

		/*
		Radiobutton
		*/
		$(document).on('click','.js-cart-radiobutton',function() {

			switch($(this).data('type')) {

				/*
				Timing
				*/
				case 'timing':

					$('.js-cart-radiobutton[data-type="timing"]').removeClass('active');
					$(this).addClass('active');

				break;

				/*
				Sector
				*/
				case 'sector':

					$('.js-cart-radiobutton[data-type="sector"]').removeClass('active');
					$(this).addClass('active');

					if(
						$('.js-cart-radiobutton[data-value="business"]').hasClass('active')
					) {
					
						$('.js-cart-business').show();

						$('.js-npt-1').addClass('npt');
						$('.js-npt-2').removeClass('npt');

					} else {
					
						$('.js-cart-business').hide();

						$('.js-npt-1').removeClass('npt');
						$('.js-npt-2').addClass('npt');
					}

				break;

			}

		});

		/*
		Checkbox
		*/
		$(document).on('click','.js-cart-checkbox',function() {

			$(this).parent().toggleClass('active');

		});

		/*
		Submit
		*/
		$(document).on('click','.js-cart-submit',function() {

			/*
			Validate fields
			*/
			var _items_array	= [];

			$('.cart__item').map(function() {

				if($(this).hasClass('js-cart-total') == false) {
				
					_items_array.push([
						$(this).data('id'),
						parseInt($(this).find('.amount').find('strong').html()),
						$(this).data('price')
					]);

				}

			});

			var _location_id 					= $('.js-area-id').val();
			var _location_name 					= $('.js-area-name').val();

			var _timing 						= $('.js-cart-radiobutton.active[data-type="timing"]').data('value');
			var _sector 						= $('.js-cart-radiobutton.active[data-type="sector"]').data('value');

			var _agree 							= $('.js-cart-checkbox[data-type="agree"]').parent().hasClass('active');

			var _info_contactperson_firstname	= $('.js-input-contactperson-firstname').val();
			var _info_contactperson_lastname	= $('.js-input-contactperson-lastname').val();
			var _info_telephone					= $('.js-input-telephone').val();
			var _info_email 					= $('.js-input-emailaddress').val();


			var _info_company					= '';
			var _info_company_streetname		= '';
			var _info_company_zipcode			= '';
			var _info_company_city				= '';

			var _invoice						= '';

			var _bedankt_url 					= globalUrl + '/' + url[1] + '/' + url[2] + '/doneren/bedankt';

			if(
				_info_contactperson_firstname == '' || 
				_info_contactperson_lastname == '' || 
				_info_telephone == '' || 
				_info_email == ''
			) {

				alert('Vul alle velden in.');
				return false;
			
			} else {

				if(
					_info_email != '' && 
					isValidMail(_info_email) == 0
				) {
					alert('Vul een geldig e-mailadres in.');
					return false;
				}

				if(_agree == false) {
					alert('Je dient akkoord gaan met onze Algemene Voorwaarden en Privacyverklaring.');
					return false;
				}

			}

			/*
			If company is active
			*/
			if(_sector == 'business') {

				_info_company		= $('.js-input-company').val();
				_invoice 			= $('.js-cart-checkbox[data="invoice"]').hasClass('active');

				_info_streetname	= $('.js-input-streetname').val();
				_info_zipcode		= $('.js-input-zipcode').val();
				_info_city			= $('.js-input-city').val();

				if(
					_info_company == '' ||
					_info_zipcode == '' ||
					_info_city == ''
				) {

					alert('Vul alle velden in.');
					return false;

				}

			}

			/*
			AJAX
			*/
			$.ajax({	
				url:globalUrl + '/a/a/donation-insert', 
				type:'POST',
				data:{
					'items'							: _items_array,

					'location_id'					: _location_id,
					'location_name'					: _location_name,

					'timing'						: _timing,
					'sector'						: _sector,

					'info_contactperson_firstname'	: _info_contactperson_firstname,
					'info_contactperson_lastname'	: _info_contactperson_lastname,
					'info_telephone'				: _info_telephone,
					'info_email'					: _info_email,

					'info_company'					: _info_company,
					'info_company_streetname'		: _info_company_streetname,
					'info_company_zipcode'			: _info_company_zipcode,
					'info_company_city'				: _info_company_city,
					'invoice'						: _invoice,
	
					'bedankt_url'					: _bedankt_url
				},
				headers: { 
					'go-load':1 
				},
				success: function(data) {

					/*
					Go to Mollie
					*/
					window.location.href = data;
					
				}
			
			});

		});

		/*
		Keep input of open-bedrag same-same
		*/
		$(document).on('input','.js-open-payment-mobile',function() {
			$('.js-open-payment-desktop').val($(this).val());
		});
		$(document).on('input','.js-open-payment-desktop',function() {
			$('.js-open-payment-mobile').val($(this).val());
		});
		
	};
	 
	// METHODS  *****************************************************************
	// METHODS *****************************************************************
	// METHODS ******************************************************************
	// METHODS ******************************************************************
	
	/*
	Add to cart
	*/
	this.updateCart = function(
		_id,
		_type,
		_price
	) {		

		/*
		AJAX
		*/
		$.ajax({	
			url:globalUrl + '/a/a/cart-update', 
			type:'POST',
			data:{
				'type'	: _type,
				'id'	: _id,
				'price'	: _price
			},
			headers: { 
				'go-load':1 
			},
			success: function(data) {

				/*
				Update: Price
				*/
				specific_doneren.getCartTotalPrice();	
				
			}
		
		});
		
	};

	/*
	Get product info
	*/
	this.getProductInfo = function(
		_id
	) {

		_id = parseInt(_id);

		var _product_price = 0;

		switch(_id) {

			case 1: // themablok
				_product_name 	= 'Themablok';
				_product_price 	= 5000;
			break;

			case 2: // een kind
				_product_name 	= 'Een kind';
				_product_price = 1500;
			break;

			case 3: // jaarprogramma
				_product_name 	= 'Jaarprogramma';
				_product_price = 30000;
			break;

			case 4: // vrijwillige coaches
				_product_name 	= 'Vrijwillige coaches';
				_product_price = 5000;
			break; 

			case 5: // diploma uitreiking
				_product_name 	= 'Diploma uitreiking';
				_product_price = 1000;
			break;

			case 6: // eindejaarsuitje
				_product_name 	= 'Eindejaarsuitje';
				_product_price = 1200;
			break;

			case 7: // ov-kaartjes
				_product_name 	= 'OV-kaartjes';
				_product_price = 50;
			break;

			case 8: // museum-kaartjes
				_product_name 	= 'Museumkaartjes';
				_product_price = 200;
			break;
 
			case 9: // begeleiding en organisatie
				_product_name 	= 'Begeleiding en organisatie';
				_product_price = 10000;
			break; 

			case 10: // gezonde lunch
				_product_name 	= 'Gezonde lunch';
				_product_price = 500;
			break;

			case 110: // open bedrag desktop
				_product_name 	= 'Open bedrag';
				_product_price = parseInt($('.js-open-payment-desktop').val());
			break;

			case 111: // open bedrag mobile
				_product_name 	= 'Open bedrag';
				_product_price = parseInt($('.js-open-payment-mobile').val());
			break;

		}

		var _product_item_array = {
			'name'	: _product_name,
			'price'	: _product_price
		};

		return _product_item_array;
		
	};

	/*
	Get cart total price
	*/
	this.getCartTotalPrice = function(
	) {

		/*
		AJAX
		*/
		$.ajax({	
			url:globalUrl + '/a/a/cart-price', 
			type:'POST',
			data:{
			},
			headers: { 
				'go-load':1 
			},
			success: function(data) {

				var _total_price 	= 0;

				var jsonData 		= JSON.parse(data);

				var itemsArray 		= jsonData.petjeaf_donatie_winkelwagen;

				if(itemsArray !== '') {

					/*
					Walk the items
					*/
					$.each(itemsArray, function(key, value){

						/*
						Open payment? Fill in the price value
						*/
						if(key == 110 || key == 111) {
							
							/*
							Set: Value price custom amount
							*/
							$('.js-open-payment-desktop').val(value.price);
						
							/*
							Get: Custom amount and add to total
							*/
							var _value_openprice = parseInt(value.price);
							
							_total_price = _total_price + (_value_openprice * value.amount);

						} else {

							_total_price = _total_price + (specific_doneren.getProductInfo(key).price * value.amount);

						}

					});
					
					_total_price_str = parseInt(_total_price).toFixed(2).replace('.', ','); // Vervang punt door komma
					
					$('.js-cart-price').html(_total_price_str);
					$('.js-cart-total').find('.price').find('span').html(_total_price_str);

					/*
					Animate
					*/
					$('.js-cart').find('.cart-content').animate({
						'transform':'scale(1.1)'
					},100).animate({
						'transform':'scale(1)'
					},100);
					
				}

				/*
				Zero, then hide
				*/
				if(parseInt(_total_price) <= 0) {

					$('.js-cart-total').hide();
					$('.information').hide();
					$('.js-cart-noitems').show();

				} else {
					$('.js-cart-total').show();
					$('.information').show();
					$('.js-cart-noitems').hide();
				}

			}
		
		});
		
	};

	/*
	Get cart items
	*/
	this.getCartItems = function(
	) {

		/*
		AJAX
		*/
		$.ajax({	
			url:globalUrl + '/a/a/cart-price', 
			type:'POST',
			data:{
			},
			headers: { 
				'go-load':1 
			},
			success: function(data) {

				var _total_price 	= 0;
				var _item_str 		= '';

				var jsonData 		= JSON.parse(data);

				var itemsArray 		= jsonData.petjeaf_donatie_winkelwagen;

				if(itemsArray !== '') {

					/*
					Walk the items
					*/
					$.each(itemsArray, function(key, value){

						var _item_price = 0;

						/*
						Open payment? Add the price
						*/
						if(key == 110 || key == 111) {

							_item_price_base 	= parseInt(value.price);

							_item_price 		= parseInt(value.price).toFixed(2).replace('.', ',');

							_total_price 		= (_total_price + parseInt(value.price));

						} else {

							_item_price_base 	= parseInt(specific_doneren.getProductInfo(key).price);

							_item_price 		= parseInt(specific_doneren.getProductInfo(key).price).toFixed(2).replace('.', ',');

							_total_price 		= _total_price + (specific_doneren.getProductInfo(key).price * value.amount);

						}	

						var _item_name 		= specific_doneren.getProductInfo(key).name;
						var _item_amount	= value.amount;

						/*
						Add item to string
						*/
						var _item_str = '<div class="cart__item" data-id="' + key + '" data-price="' + _item_price_base + '">' +
							'<div class="left">' +
								'<div class="name">' + _item_name + '</div>' +
							'</div>' +
							'<div class="right-desktop desktop">' +
								'<div class="price">&euro; ' + _item_price + '</div>' +
								'<div class="amount"><strong>' + _item_amount + '</strong> x</div>' +
								'<div class="adjust">';
								
									/*
									Open bedrag: No add button
									*/
									if(key != 110 && key != 111) {

										_item_str += '<div class="bt js-addorremove" data-type="add">' +
											'<span>+</span>' +
										'</div>';

									}

									_item_str += '<div class="bt js-addorremove" data-type="remove">' +
										'<span>-</span>' +
									'</div>' +
								'</div>' +
							'</div>' +
							'<div class="right-mobile mobile">' +
								'<div class="adjust">' +
									'<div class="bt js-addorremove" data-type="add">' +
										'<span>+</span>' +
									'</div>' +
									'<div class="bt js-addorremove" data-type="remove">' +
										'<span>-</span>' +
									'</div>' +
								'</div>' +
								'<div class="amount"><strong>' + _item_amount + '</strong> x</div>' +
								'<div class="price">&euro; <span>' + _item_price + '</span></div>' +
							'</div>' +
						'</div>';

						/*
						Append the item
						*/
						$('.js-cart-items').append(_item_str);
					
					});

					/*
					Add: Total
					Add: Total
					Add: Total
					Add: Total
					*/

					//_total_price = parseInt(_total_price).toFixed(2).replace('.', ','); // Vervang punt door komma

					var _total_str =
						'<div class="cart__item total js-cart-total hidden">' +
							'<div class="left">' +
								'<div class="name">Totaal</div>' +
							'</div>' +
							'<div class="right-desktop desktop">' +
								'<div class="price">&euro; <span></span></div>' +
							'</div>' +
							'<div class="right-mobile mobile">' +
								'<div class="price">&euro; <span></span></div>' +
							'</div>' +
						'</div>';

					$('.js-cart-items').append(_total_str);
					
				}

				/*
				Update cart
				*/
				specific_doneren.getCartTotalPrice();

			}
		
		});
		
	};


		
}).apply(specific_doneren); 