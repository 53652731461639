/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	17-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_scroller = {};

(function(){
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.onready = function() {
		
		var _get_page = url[4];

		var _marge;
		if($(window).width() >= 500) {
			_marge = 100;
		} else {
			_marge = 130;
		}

		var _speed = 500;

		if(url[3] == 'kind-en-ouder') {
		switch(_get_page) {

			default:

				$('html, body').stop().animate({
			        scrollTop: 0
			    }, _speed);

			break;
			
			case 'programma':

				$('html, body').stop().animate({
			        scrollTop: $('.block-watdoetpetjeaf').offset().top - _marge
			    }, _speed);

			break;

			case 'team':

				$('html, body').stop().animate({
			        scrollTop: $('.block-wiezijnwij').offset().top - _marge
			    }, _speed);

			break;

			case 'contact':

				$('html, body').stop().animate({
			        scrollTop: $('.block-contact').offset().top - _marge
			    }, _speed);

			break;

			}
		} else {

			switch(_get_page) {

				case 'uniek':
				case 'programma':

					$('html, body').stop().animate({
				        scrollTop: $('.block-info-columns').offset().top - _marge
				    }, _speed);

				break;

				case 'partners':

					$('html, body').stop().animate({
				        scrollTop: $('.block-partners').offset().top - _marge
				    }, _speed);

				break;

				case 'contact':

					$('html, body').stop().animate({
				        scrollTop: $('.block-contact').offset().top - _marge
				    }, _speed);

				break;

			}


		}

	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	// none
	
}).apply(specific_scroller); 