/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2021.

!Changelog

2021
	20-11-2021
	- [BORN] This class is born. Hooray!

!Wishlist

	none
	
*/
  
var specific_swipers = {};

(function(){
	 
	// ONREADY ********************************************************************
	// ONREADY ********************************************************************
	// ONREADY ********************************************************************
	// ONREADY ********************************************************************
	
	this.onready = function() {

		/*
		Infinite Slides
		Infinite Slides
		Infinite Slides
		Infinite Slides
		*/
		// $('.js-photos-infinite').infiniteslide({
		// 	'speed': 25,
		// 	'direction': 'left',
		// 	'pauseonhover': false,
		// 	'responsive': true, 
		// 	'clone': 10
		// });

		/*
		Swiper: Slideshow
		Swiper: Slideshow
		Swiper: Slideshow
		Swiper: Slideshow
		*/
		var swiper_slideshow = new Swiper('.js-locatie-swiper', {

		  loop: true,

		  navigation: {
		    nextEl: '.js-news-button-next',
		    prevEl: '.js-news-button-prev',
		  },

		  slidesPerView :1,

		  // autoplay: {
		  //   delay: 4000,
		  // },

		  breakpoints: {
		    
		    500: {
		      slidesPerView: 1,
		      spaceBetween: 20
		    }

		  }

		});

		/*
		Swiper: News
		Swiper: News
		Swiper: News
		Swiper: News
		*/
		var swiper_news_1 = new Swiper('.js-news-swiper-1', {

		  loop: true,

		  navigation: {
		    nextEl: '.js-news-button-next',
		    prevEl: '.js-news-button-prev',
		  },

		  slidesPerView :1,

		  breakpoints: {
		    
		    500: {
		      slidesPerView: 1,
		      spaceBetween: 20
		    }

		  }

		});

		/*
		Swiper: News
		Swiper: News
		Swiper: News
		Swiper: News
		*/
		var swiper_news_2 = new Swiper('.js-news-swiper-2', {

		  loop: true,

		  navigation: {
		    nextEl: '.js-news-button-next',
		    prevEl: '.js-news-button-prev',
		  },

		  slidesPerView :2,

		  breakpoints: {
		    
		    500: {
		      slidesPerView: 1,
		      spaceBetween: 20
		    }

		  }

		});

		/*
		Swiper: Partners
		Swiper: Partners
		Swiper: Partners
		Swiper: Partners
		*/	
		var swiper_partners_1 = new Swiper('.js-partners-swiper-1', {

		  loop: false,

		  slidesPerView :12,

		  autoplay: {
		    delay: 1000,
		    disableOnInteraction: false,
		  },

		  breakpoints: {
		    
		    500: {
		      slidesPerView: 2,
		      spaceBetween: 20
		    }

		  }

		});

		var swiper_partners_2 = new Swiper('.js-partners-swiper-2', {

		  loop: false,

		  slidesPerView :12,

		  autoplay: {
		    delay: 1000,
		    disableOnInteraction: false,
		  },

		  breakpoints: {
		    
		    500: {
		      slidesPerView: 2,
		      spaceBetween: 20
		    }

		  }

		});

	};
	 
	// METHODS  *****************************************************************
	// METHODS *****************************************************************
	// METHODS ******************************************************************
	// METHODS ******************************************************************
	

	// none yet
	

		
}).apply(specific_swipers); 