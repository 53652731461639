/*
Version 0.110

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	08-03-2019
	- [CHANGE] Added variable check to set height and with when not given in on windowResize();
	11-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_interactions_window = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	// none
	
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
		/*
		Window: resize
		*/
		this.windowResize = function(scrolled,h,w) {
			
			/*
			Set: Variables
			*/
			if(typeof h === 'undefined') {
				h = $(window).height();
			}
			if(typeof w === 'undefined') {
				w = $(window).width();
			}

			// document.querySelector(':root').style.setProperty('--vh', window.innerHeight/100 + 'px');

			/*
			Roosters
			*/
			if(_device == 'desktop') {

				var _check_height1 = $('.js-check-center1').height();
				var _check_height2 = $('.js-check-center2').find('.roosters').height();

				if(_check_height2 < _check_height1) {
					$('.js-check-center2').find('.roosters').addClass('center');
				}


			}

			/*
			Header: Menu button
			*/
			if($(window).width() < 500) {

				$('.js-header-menubutton').stop().animate({
					'top':10
				},200);

			} else {

				$('.js-header-menubutton').stop().animate({
					'top':20
				},200);
				
			}
			
		};
		
		/*
		Window: scroll
		*/
		this.windowScroll = function(scrolled,h,w) {

			/*
			Cart: Scroll to Fixed
			*/
			var _top_marge = 0;

			if(scrolled > 400) {

				if(w <= 500) {
					_top_marge = 120;
				} else {
					_top_marge = 80;
				}

				$('.cart-wrapper-sticky').stop().animate({
					'top':_top_marge
				},200);

			} else {

				$('.cart-wrapper-sticky').stop().animate({
					'top':-100
				},200);
				
			}

		};
		
		
		/*
		Window: orientation
		*/
		this.windowOrientationChange = function() {
			
		};

		/*
		Is in view?
		*/
		this.isInView = function (
			_element,
			_position
		){

		};
		
}).apply(specific_interactions_window); 