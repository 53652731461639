/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	17-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_googlemaps = {};

(function(){
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.onready = function() {
		
        /*
        Init
        */
        google.maps.event.addDomListener(window, 'load', specific_googlemaps.init());

        setTimeout(function() {
        	
        	specific_googlemaps.addMarkers();

    	},200);
	
	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	this.init = function() {

		window.marker = null;

		var _zoom_factor;

		if(_device == 'desktop') {
			_zoom_factor = 9;
		} else {
			_zoom_factor = 7;
		}

        var netherlands = new google.maps.LatLng(52.08156689272183, 5.302401110739786);

        var style = [
		  {
		    "elementType": "geometry",
		    "stylers": [
		      {
		        "color": "#f5f5f5"
		      }
		    ]
		  },
		  {
		    "elementType": "labels.icon",
		    "stylers": [
		      {
		        "visibility": "off"
		      }
		    ]
		  },
		  {
		    "elementType": "labels.text.fill",
		    "stylers": [
		      {
		        "color": "#616161"
		      }
		    ]
		  },
		  {
		    "elementType": "labels.text.stroke",
		    "stylers": [
		      {
		        "color": "#f5f5f5"
		      }
		    ]
		  },
		  {
		    "featureType": "administrative.land_parcel",
		    "elementType": "labels.text.fill",
		    "stylers": [
		      {
		        "color": "#bdbdbd"
		      }
		    ]
		  },
		  {
		    "featureType": "poi",
		    "elementType": "geometry",
		    "stylers": [
		      {
		        "color": "#eeeeee"
		      }
		    ]
		  },
		  {
		    "featureType": "poi",
		    "elementType": "labels.text.fill",
		    "stylers": [
		      {
		        "color": "#757575"
		      }
		    ]
		  },
		  {
		    "featureType": "poi.park",
		    "elementType": "geometry",
		    "stylers": [
		      {
		        "color": "#e5e5e5"
		      }
		    ]
		  },
		  {
		    "featureType": "poi.park",
		    "elementType": "labels.text.fill",
		    "stylers": [
		      {
		        "color": "#9e9e9e"
		      }
		    ]
		  },
		  {
		    "featureType": "road",
		    "elementType": "geometry",
		    "stylers": [
		      {
		        "color": "#ffffff"
		      }
		    ]
		  },
		  {
		    "featureType": "road.arterial",
		    "elementType": "labels.text.fill",
		    "stylers": [
		      {
		        "color": "#757575"
		      }
		    ]
		  },
		  {
		    "featureType": "road.highway",
		    "elementType": "geometry",
		    "stylers": [
		      {
		        "color": "#dadada"
		      }
		    ]
		  },
		  {
		    "featureType": "road.highway",
		    "elementType": "labels.text.fill",
		    "stylers": [
		      {
		        "color": "#616161"
		      }
		    ]
		  },
		  {
		    "featureType": "road.local",
		    "elementType": "labels.text.fill",
		    "stylers": [
		      {
		        "color": "#9e9e9e"
		      }
		    ]
		  },
		  {
		    "featureType": "transit.line",
		    "elementType": "geometry",
		    "stylers": [
		      {
		        "color": "#e5e5e5"
		      }
		    ]
		  },
		  {
		    "featureType": "transit.station",
		    "elementType": "geometry",
		    "stylers": [
		      {
		        "color": "#eeeeee"
		      }
		    ]
		  },
		  {
		    "featureType": "water",
		    "elementType": "geometry",
		    "stylers": [
		      {
		        "color": "#c9c9c9"
		      }
		    ]
		  },
		  {
		    "featureType": "water",
		    "elementType": "labels.text.fill",
		    "stylers": [
		      {
		        "color": "#9e9e9e"
		      }
		    ]
		  }
		];

        var mapOptions = {

            center: netherlands,

            mapTypeId: google.maps.MapTypeId.ROADMAP,
            zoom: _zoom_factor,

            backgroundColor: "#fff",

            panControl: false,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            overviewMapControl: false,
            zoomControlOptions: {
                style: google.maps.ZoomControlStyle.SMALL
            }

        };

        map = new google.maps.Map(document.getElementById('js-maps'), mapOptions);

        /*
        Map
        */
        var mapType = new google.maps.StyledMapType(style, {
            name: "Grayscale"
        });
        map.mapTypes.set('grey', mapType);
        map.setMapTypeId('grey');

        /*
        Create: Marker
        */
        var marker_image 	= '<?php $url->show(); ?>/img/static/google-marker.png';
        var pinIcon 		= new google.maps.MarkerImage(marker_image, null, null, null, new google.maps.Size(35, 35));

	};

	this.addMarkers = function(
		coordinates,
		name
	) {

		/*
		XHR
		*/
		$.ajax({
			url:globalUrl + '/a/a/googlemaps-get',
			method: 'POST',
			headers: { 
				'go-load':1 
			}
		})
		.done(function(data) {

			var split_areas = data.split("#");

			for (var i = 0; i < split_areas.length; i++) {

				var split_area = split_areas[i].split("*");

				var name 	= split_area[0];
				var url 	= split_area[1];
				var co 		= split_area[2];

				if(
					typeof name != 'undefined' &&
					typeof co != 'undefined' &&
					co != ''
				) {
					
					specific_googlemaps.addMarker(
						co,
						map,
						name,
						url
					);

				}

			}

		})
		.always(function() {
		});

	};

	this.addMarker = function(
		location,
		map,
		name,
		url
	) {

		/*
        Marker: Style
        */
        var marker_image 	= globalUrl + '/img/static/google-marker.png';
        var pinIcon 		= new google.maps.MarkerImage(marker_image, null, null, null, new google.maps.Size(35, 35));

        location = location.split(",");

        /*
        Make marker
        */
		var marker = new google.maps.Marker({
			position 	:new google.maps.LatLng(location[0],location[1]),
			icon 		:pinIcon,
			map 		:map,
			url 		:globalUrl + '/kies-jouw-locatie/' + url,
			boxStyle: {
		        background: '#FFFFFF',
		        color: '#000',
		        textAlign: "center",
		        fontSize: "8pt",
		        width: "50px"
		    },
			// label: {
			// 	text: name,
   //   			color: '#000',	
   //   			fontSize: '12px'
   //   		}
		});

		var infowindow = new google.maps.InfoWindow();

		infowindow.setContent('<div class="label">' + name + '</div>');

		/*
		Interactions
		*/
		marker.addListener('mouseover', function() {
		    infowindow.open(map, this);
		});

		marker.addListener('mouseout', function() {
		    infowindow.close();
		});

		marker.addListener('click', function() {
		    window.location.href = this.url;
		});

	};
	
}).apply(specific_googlemaps); 