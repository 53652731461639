/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	17-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_interactions_init = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	/**
	None yet
	**/
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.onready = function() {
		
		/**
		Unbind: Unnecessary listeners
		**/
		//$(document).off('click.cookieWallSubmit');
		//$(document).off('click.cookieWallTabs');
		//$(document).off('click.cookieBarSubmit');
		
		/*
		Close the mobilemenu always on route
		*/
		specific_mobilemenu.toggle(1);

		/*
		Trigger: Instagram
		*/
		//specific_instagram.onready('thumb',8,true);

		/*
		Trigger: Facebook
		*/
		//specific_facebook.onready(6,true);

		/*
		Catch and active URL
		*/
		// var _url_active_main = './';
		
		// $('.js-nav-item').removeClass('active');
		
		// if(url[2] !== '') { 
		// 	_url_active_main = url[1] + '/' + url[2]; 
		// } else {
		// 	if(url[1] !== '') { 
		// 		_url_active_main = url[1];
		// 	}
		// }

		// if(_url_active_main !== '') {
		// 	$('.js-nav-item[data-go-url="' + _url_active_main + '"]').addClass('active');
		// }

		/*
		Body: Blue background
		*/
		$('html,body').removeClass('blue');
		$('footer').show();

		if(
			url[1] == 'kies-jouw-locatie' || 
			url[2] == 'meedoen'
		) {

			if(url[1] == 'kies-jouw-locatie') {
				$('html,body').addClass('blue');
				$('.js-header-menubutton').hide();
			}

			$('footer').hide();
		}
		
		/*
		Home, Location or Global page?
		*/
		if(
			url[1] == '' ||
			url[1] == 'nederland'
		) {

			specific_homepage.onready();

		} else {

			switch(url[1]) {

				default:

					specific_location.onready();

				break;

				case 'kies-jouw-locatie':

					specific_chooselocation.onready();
					
				break;

			}
			
		}

		if(
			url[1] == 'algemene-voorwaarden' ||
			url[1] == 'privacyverklaring'
		) {
			$('.js-header-menubutton').hide();
			$('.js-header-fixed').hide();
		}

		/*
		Cart
		*/
		if(
			url[3] == 'doneren' ||
			url[5] == 'doneren'
		) {
			
			specific_doneren.getCartTotalPrice();

			if(
				url[4] == 'winkelwagen' ||
				url[6] == 'winkelwagen'

			) {
				specific_doneren.getCartItems();
			}

		}

		/*
		Overlays: Close
		*/
		specific_overlaymenu.toggle(0);

		/*
		Colorbox
		*/
		$(".js-colorbox-group").colorbox({
			rel:'colorbox-group', 
			maxWidth:'75%', 
			maxHeight:'75%',
			current: "{current} / {total}"
		});

		/*
		Colorbox: Button
		*/
		$(document).on('click','.js-colorbox-click',function() {
			$('.js-colorbox-first').trigger('click');
		});

		/*
		Scroller
		*/
		specific_scroller.onready(
		);

		/*
		Google Maps
		*/
		if(url[2] == 'op-de-kaart') {
			specific_googlemaps.onready();
		}

		/*
		Vacatures
		*/	
		if(
			url[1] == 'nederland' && 
			url[2] == 'vacatures'
		) {

			$('.masonry').masonry({
			  itemSelector: '.col-md-6'
			});
		}

	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	// none
	
}).apply(specific_interactions_init);