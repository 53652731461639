/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2021.

!Changelog

2021
	20-11-2021
	- [BORN] This class is born. Hooray!

!Wishlist

	none
	
*/
  
var specific_chooselocation = {};

(function(){
	 
	// ONREADY ********************************************************************
	// ONREADY ********************************************************************
	// ONREADY ********************************************************************
	// ONREADY ********************************************************************
	
	this.onready = function() {

		/*
		Reset: Header: City hide
		*/
		$('.js-header-city').hide();
		$('.js-header-fixed').animate({
			'top':-40
		},200);
		$('.js-header-menubutton').animate({
			'top':-80
		},200);

		/*
		Init: Step 1
		*/
		$('.js-chooselocation-step').hide();
		$('.js-chooselocation-step1').show();
		
		/*
		Button: Close
		Button: Close
		Button: Close
		Button: Close
		*/
		$(document).on('click','.js-chooselocation-close',function() {
			history.back(-1);
		});


		/*
		Set: Input focus
		*/
		//$('.js-chooselocation-autocomplete').focus();

		/*
		Set: Input focus
		*/
		$('.js-chooselocation-autocomplete').on('input',function() {
			specific_chooselocation.autocomplete(
				$(this).val()
			);
		});

	};
	 
	// METHODS  *****************************************************************
	// METHODS *****************************************************************
	// METHODS ******************************************************************
	// METHODS ******************************************************************
	
	this.autocomplete = function(
		input
	) {

		var _input 		= input.toLowerCase();

		var _counter 	= 1;
			
		$('.js-places').each(function() {

			//if(_counter == 1) {

				$(this).find('.js-location').each(function() {

					_name 	= $(this).find('.menu-choose').text().toLowerCase();
					
					/*
					Show or hide?
					*/
					$(this).show();

					if(!_name.includes(_input)) {
						$(this).hide();
					}

					/*
					Loop and check Town
					*/
					$('.js-stad').each(function() {

						if($(this).is(':visible')) {
							$(this).next().find('.js-location').show();
						}

					});

				});

			//} 

			//_counter++;

		});

	};
	
	this.autocomplete_old = function(
		input
	) {	

		var _input 		= input.toLowerCase();
		
		/*
		Search equal names
		Search equal names
		Search equal names
		Search equal names
		*/
		$('.js-menu-town').each(function() {

			_name 	= $(this).text().toLowerCase();

			/*
			Town
			*/
			$(this).parent().show();

			if(!_name.includes(_input)) {
				$(this).parent().hide();
			}

		});

		/*
		Area: Check
		Area: Check
		Area: Check
		Area: Check
		*/
		$('.js-menu-area').each(function() {

			_name 	= $(this).text().toLowerCase();

			/*
			Neighberhood
			*/
			$(this).parent().show();

			if(!_name.includes(_input)) {
				$(this).parent().hide();
			}

		});

		/*
		Sub: Check
		Sub: Check
		Sub: Check
		Sub: Check
		*/
		$('.js-sub').each(function() {

			var _hide 	= 0;
			var _count	= 1;

			$(this).find('.js-location-area').each(function() {

				if(_count == 1) {

				} else {

					if(
						$(this).is(':visible')
					) {
						_hide = 1;
					}

				}

				_count++;

			});	

			$(this).prev().show();

			if(
				_hide == 1
			) {
				$(this).prev().hide();
			}

		});

		/*
		Search equal names
		Search equal names
		Search equal names
		Search equal names
		*/
		$('.js-menu-area').each(function() {

			if(
				$(this).parent().hasClass('.sub') &&
				$(this).is(':visible')
			) {
				$(this).parent().prev().show();
			}

		});

	};

}).apply(specific_chooselocation); 