/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2021.

!Changelog

2021
	20-11-2021
	- [BORN] This class is born. Hooray!

!Wishlist

	none
	
*/
  
var specific_location = {};

(function(){
	 
	// ONREADY ********************************************************************
	// ONREADY ********************************************************************
	// ONREADY ********************************************************************
	// ONREADY ********************************************************************
	
	this.onready = function() {
		
		/*
		Set: Area name
		*/
		$('.js-header-city').html(
			$('.js-area-name').val()
		);

		/*
		Change location link back
		*/
		$('.js-header-logo').attr('href',globalUrl + '/kies-jouw-locatie/' + url[1]);
		$('.js-header-logo').addClass('js-nogo');
		
		/*
		Listeners
		*/
		$(document).on('click','.js-readmore',function() {
			$('.js-readmore-hidden').slideDown();
			$('.js-readmore').hide();
		});

		/*
		Header: City show
		*/
		$('.js-header-city').show();
		$('.js-header-fixed').show().stop().animate({
			'top':0
		},200);	

		if($(window).width() < 500) {

			$('.js-header-menubutton').show().stop().animate({
				'top':10
			},0);

		} else {

			$('.js-header-menubutton').show().stop().animate({
				'top':20
			},0);
			
		}

		/*
		Set: Headertext
		*/
		if(url[3] == 'algemeen') {
			$('.js-header-text').html('Je bekijkt nu de <strong>algemene</strong> versie. Zoek je de kind & ouder versie? <a href="' + globalUrl + '/' + url[1] + '/' + url[2] + '/kind-en-ouder">Klik dan hier</a>');
		} else {
			$('.js-header-text').html('Je bekijkt nu de <strong>kind & ouder</strong> versie. Zoek je de algemene versie? <a href="' + globalUrl + '/' + url[1] + '/' + url[2] + '/algemeen">Klik dan hier</a>');
		}

		/*
		Load: Overlaymenu
		*/
		setTimeout(function() {
			specific_overlaymenu.load(
				url[3]
			);
		},200);
		
		/*
		Init: Swipers
		*/
		specific_swipers.onready();

	};
	 
	// METHODS  *****************************************************************
	// METHODS *****************************************************************
	// METHODS ******************************************************************
	// METHODS ******************************************************************
	

	// none yet
	

		
}).apply(specific_location); 