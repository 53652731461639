/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2021.

!Changelog

2021
	20-11-2021
	- [BORN] This class is born. Hooray!

!Wishlist

	none
	
*/
  
var specific_bijdragen = {};

(function(){
	 
	// ONREADY ********************************************************************
	// ONREADY ********************************************************************
	// ONREADY ********************************************************************
	// ONREADY ********************************************************************
	
	this.onready = function() {
			
		$(document).on('click','.js-keuze-item-button',function() {

			var _locatie 	= $('.js-location-name').html();
			var _text 		= 'Beste ' + _locatie + ', ' + $(this).data('text');

			$('.form-input-bijdragen').val(_text);

			/*
			Scroll 2
			*/
			$('html, body').animate({
		        scrollTop: $('.contactformulier').offset().top - 200
		    }, 1000); // 1000 is de duur van de animatie in milliseconden

		});

		/*
		Slide out
		*/
		$(document).on('click','.js-keuze-item-inside',function() {

			var _id = $(this).data('id');

			$('.keuze-item-slideout').stop().slideUp();

			$('.js-keuze-item-inside').find('.arrow-svg').stop().css({
				'transform':'translate(-50%,-50%) rotate(0)',
				// 'margin-top':'-6px'
			});

			$(this).find('.arrow-svg').stop().css({
				'transform':'translate(-50%,-50%) rotate(90deg)',
				// 'margin-top':'-10px'
			});

			$('.js-keuze-item-slideout-' + _id).stop().slideDown();

		});

	};
	 
	// METHODS  *****************************************************************
	// METHODS *****************************************************************
	// METHODS ******************************************************************
	// METHODS ******************************************************************
	
	// none yet
		
}).apply(specific_bijdragen); 