/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	17-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_interactions_firstload = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	/**
	None yet
	**/
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.onready = function() {
		
		/*
		Trigger: modal and popup preloader
		*/
		images.preload();
		modal.onready();
		popup.onready();
		form.onready();
		if(pollConnection === 1) { poll_connection.onready(); }
		
		/*
		Trigger: onready classes
		*/
		//specific_mobilemenu.onready();
		specific_videoplayer.onready();
		specific_overlaymenu.onready();
		specific_faq.onready();
		specific_bijdragen.onready();
		specific_doneren.onready();

		/*
		Set: AV and PV clicks
		Set: AV and PV clicks
		Set: AV and PV clicks
		Set: AV and PV clicks
		*/
		$(document).on('click','.js-click-av',function(e) {

			e.preventDefault();

			if(
				url[1] == '' || 
				url[1] == 'nederland'
			) {
				window.open(globalUrl + '/nederland/algemene-voorwaarden', '_blank');
			} else {
				window.open(globalUrl + '/' + url[1] + '/' + url[2] + '/' + url[3] + '/algemene-voorwaarden', '_blank');
			}
			
		});

		$(document).on('click','.js-click-pv',function(e) {

			e.preventDefault();

			if(
				url[1] == '' || 
				url[1] == 'nederland'
			) {
				window.open(globalUrl + '/nederland/privacy-statement', '_blank');
			} else {
				window.open(globalUrl + '/' + url[1] + '/' + url[2] + '/' + url[3] + '/privacy-statement', '_blank');
			}
			
		});

	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	// none
	
}).apply(specific_interactions_firstload); 