/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2021.

!Changelog

2021
	20-11-2021
	- [BORN] This class is born. Hooray!

!Wishlist

	none
	
*/
  
var specific_homepage = {};

(function(){
	 
	// ONREADY ********************************************************************
	// ONREADY ********************************************************************
	// ONREADY ********************************************************************
	// ONREADY ********************************************************************
	
	this.onready = function() {
		
		/*
		Reset: Header: City hide
		*/
		$('.js-header-city').hide();
		$('.js-header-fixed').stop().animate({
			'top':-40
		},0);
		$('.js-header-menubutton').hide().stop().animate({
			'top':-80
		},0);

		/*
		Set: Headertext
		*/
		$('.js-header-text').html('Je bekijkt nu de <strong>Nederland</strong> versie. Zoek je een locatie? <a href="' + globalUrl + '/kies-jouw-locatie">Klik dan hier</a>');

		/*
		Header: City show
		*/
		if(
			url[2] != '' &&
			url[2] != 'op-de-kaart' &&
			url[2] != 'kies-jouw-locatie'
		) {
			
			$('.js-header-fixed').stop().animate({
				'top':0
			},200);
			
		}

		/*
		Init: Swipers
		*/
		specific_swipers.onready();

	};
	 
	// METHODS  *****************************************************************
	// METHODS *****************************************************************
	// METHODS ******************************************************************
	// METHODS ******************************************************************
	

	// none yet
	

		
}).apply(specific_homepage); 