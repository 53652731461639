/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2021.

!Changelog

2021
	20-11-2021
	- [BORN] This class is born. Hooray!

!Wishlist

	none
	
*/
  
var specific_overlaymenu = {};

(function(){
	 
	// ONREADY ********************************************************************
	// ONREADY ********************************************************************
	// ONREADY ********************************************************************
	// ONREADY ********************************************************************
	
	this.onready = function() {
			
		$(document).on('click','.js-header-menubutton',function() {
			specific_overlaymenu.toggle();
		});

	};
	 
	// METHODS  *****************************************************************
	// METHODS *****************************************************************
	// METHODS ******************************************************************
	// METHODS ******************************************************************
	
	this.load = function(
		w
	) {

		/*
		*/
		w = w.replace(/-/g,'');

		/*
		Set: Extra URL
		*/
		var url_rest;
		
		if(
			url[3] == 'algemeen' ||
			url[3] == 'kind-en-ouder'
		) {
			url_rest = url[1] + '/' + url[2];
		} else {
			url_rest = url[1];
		}

		/*
		Load the URL
		*/
		if(
			typeof loadMenu !== 'undefined' && 
			loadMenu !== ''
		) {
			loadMenu.abort();
		}

		loadMenu = $.ajax({
			url:globalUrl + '/a/l/overlaymenu-' + w + '/' + url_rest,
			headers: { 
				'go-load':1 
			}
		})
		.done(function(data) {
			
			$('.js-overlay-menu').html(data);

			/*
			Set: Area name
			*/
			$('.js-header-city').html(
				$('.js-area-name').val()
			);

		})
		.always(function() {
		});

	};

	this.toggle = function(
		w
	) {

		var _what = 0;

		if(w == 0) {

			_what = 'close';

		} else {

			if(
				$('.js-overlay-menu').hasClass('active')
			) {
				_what = 'close';
			} else {
				_what = 'open';
			}

		}

		/*
		Open or close?
		*/
		if(
			_what == 'open'
		) {

			/*
			Open
			*/
			$('.js-overlay-menu').addClass('active');
			$('.js-header-menubutton').addClass('active');

			/*
			Remove Bodyscroll
			*/
			$('html,body').addClass('helper-body-no-scroll');


		} else {

			/*
			Close
			*/
			$('.js-overlay-menu').removeClass('active');
			$('.js-header-menubutton').removeClass('active');

			/*
			Add Bodyscroll
			*/
			$('html,body').removeClass('helper-body-no-scroll');


		}

	};
		
}).apply(specific_overlaymenu); 